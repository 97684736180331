import React, { useState, useEffect } from 'react';
import { Box, Input, IconButton, Stack, Typography } from '@mui/joy';
import { AddCircle as AddCircleIcon, RemoveCircle as RemoveCircleIcon } from '@mui/icons-material';

const DynamicInputs = ({ title, name, needSecond, secondName, onChange }) => {
    const initialInput = needSecond ? [["", ""]] : [[""]];
    const [inputs, setInputs] = useState(initialInput);

    const handleAddInput = () => {
        const newInput = needSecond ? ["", ""] : [""];
        setInputs([...inputs, newInput]);
    };

    const handleRemoveInput = (index) => {
        const updatedInputs = inputs.filter((_, i) => i !== index);
        setInputs(updatedInputs);
    };

    const handleInputChange = (rowIndex, inputIndex, value) => {
        const newInputs = [...inputs];
        newInputs[rowIndex][inputIndex] = value;
        setInputs(newInputs);
    };

    useEffect(() => {
        const formattedResult = needSecond
            ? inputs.map(([first, second]) => `${first}:${second}`).join(';')
            : inputs.map(([value]) => `https://citrus-server.online/?url=${value}`).join(',');

        onChange(formattedResult);
    }, [inputs, needSecond, onChange]);

    return (
        <Box sx={{ width: '100%', margin: 'auto' }}>
            <Typography>{title}</Typography>
            <Stack spacing={2}>
                {inputs.map((inputRow, rowIndex) => (
                    <Box
                        key={rowIndex}
                        sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
                    >
                        {inputRow.map((input, inputIndex) => (
                            <Input
                                key={inputIndex}
                                placeholder={
                                    inputIndex === 0
                                        ? `${name} #${rowIndex + 1}`
                                        : `${secondName} #${rowIndex + 1}`
                                }
                                value={input}
                                onChange={(e) =>
                                    handleInputChange(rowIndex, inputIndex, e.target.value)
                                }
                                sx={{
                                    flexGrow: 1,
                                    color: 'black',
                                    backgroundColor: 'white',
                                }}
                                color="warning"
                            />
                        ))}
                        <IconButton
                            color="danger"
                            onClick={() => handleRemoveInput(rowIndex)}
                            disabled={inputs.length === 1}
                        >
                            <RemoveCircleIcon />
                        </IconButton>
                    </Box>
                ))}
            </Stack>

            <IconButton
                color="warning"
                onClick={handleAddInput}
                sx={{ marginTop: 2 }}
            >
                <AddCircleIcon />
            </IconButton>
        </Box>
    );
};

export default DynamicInputs;
