import React from 'react';
import { useEffect, useState } from 'react';
import { Button, Card, Input, Typography } from "@mui/joy";
import { Editor, convertToRaw } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EditorState } from 'draft-js';
import DynamicInputs from '../components/DynamicInputs';
import axios from 'axios';
import { stateToHTML } from 'draft-js-export-html';

const Panel = () => {
    useEffect(() => {
        document.title = 'Цитрусмолл — Админ панель';
    }, []);

    const [editorState, setEditorState] = useState(EditorState.createEmpty());

    const [user, setUser] = useState(null);

    useEffect(() => {
        const storedEmail = localStorage.getItem("email");
        const storedPassword = localStorage.getItem("password");
    
        if (storedEmail && storedPassword) {
            handleSubmit({
                preventDefault: () => {},
                target: {
                    email: { value: storedEmail },
                    password: { value: storedPassword },
                },
            });
        }
    }, []);

    const handleSubmit = async (event) => {
        event.preventDefault();

        const email = event.target.email.value;
        const password = event.target.password.value;

        try {
            const response = await fetch('https://supersale.pro/api/auth/login', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    email: email,
                    password: password,
                    remember: "on"
                }),
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            localStorage.setItem("email", email);
            localStorage.setItem("password", password);

            const data = await response.json();
            setUser(data);
        } catch (error) {
            console.error('There was a problem with the fetch operation:', error);
        }
    };

    const [rewiews, setRewiews] = useState('');
    const [images, setImages] = useState('');

    const handleRewiewsChange = (value) => {
        console.log(value);
        setRewiews(value);
    };

    const handleImagesChange = (value) => {
        console.log(value);
        setImages(value);
    };

    const [htmlContent, setHtmlContent] = useState('');
    const handleEditorChange = (state) => {
        setEditorState(state);
        const contentState = state.getCurrentContent();
        const html = stateToHTML(contentState);
        setHtmlContent(html);
    };

    const [productName, setProductName] = React.useState('');
    const [productCategory, setProductCategory] = React.useState(0);
    const [productImage, setProductImage] = React.useState('');
    const [productRaiting, setProductRaiting] = React.useState(0);
    const [productPrice, setProductPrice] = React.useState(0.00);
    const [productSmallDesc, setProductSmallDesc] = React.useState('');

    const handleSubmitUpload = async () => {
        const data = {
            "isNew": true,
            "activity": true,
            "categoryId": productCategory,
            "name": productName,
            "price": productPrice,
            "sourceImage": "https://citrus-server.online/?url=" + productImage,
            "article": null,
            "sourceUrl": null,
            "oneCName": null,
            "forSite": true,
            "rating": productRaiting,
            "shortDescription": productSmallDesc,
            "longDescription": htmlContent,
            "additionaImages": images,
            "comments": rewiews
        };

        try {
            const response = await axios.post(
                'https://supersale.pro/api/products',
                data,
                {
                    headers: {
                        Authorization: `Bearer ${user.token}`,
                        "Content-Type": 'application/json'
                    }
                }
            );

            if (response.status >= 200 && response.status < 300) {
                alert(`Товар ${data.name} успешно создан!`);
                window.location.reload(); 
            } else {
                alert("Произошла ошибка при создании товара, проверьте данные");
            }
        } catch {
            alert("Произошла ошибка при создании товара, проверьте данные");
        }
    };

    if (user?.token) {
        return (
            <div className="page">
                <Typography level='h3'>Добавление товара</Typography>
                <Input
                    sx={{ color: 'black', mb: 1, backgroundColor: 'white' }}
                    color='warning'
                    placeholder='Название товара...'
                    onChange={(event) => setProductName(event.target.value)}
                />
                <Input
                    sx={{ color: 'black', mb: 1, backgroundColor: 'white' }}
                    color='warning'
                    placeholder='ID категории...'
                    type='number'
                    onChange={(event) => setProductCategory(event.target.value)}
                />
                <Input
                    sx={{ color: 'black', mb: 1, backgroundColor: 'white' }}
                    color='warning'
                    placeholder='Ссылка на картинку...'
                    onChange={(event) => setProductImage(event.target.value)}
                />
                <Card variant='plain' sx={{ mb: 1, border: '1px solid orange', backgroundColor: 'white' }}>
                    <DynamicInputs onChange={handleImagesChange} needSecond={false} title="Дополнительные изображения" name="Изображение" />
                </Card>
                <Input
                    sx={{ color: 'black', mb: 1, backgroundColor: 'white' }}
                    color='warning'
                    placeholder='Рейтинг...'
                    type='number'
                    onChange={(event) => setProductRaiting(event.target.value)}
                />
                <Input
                    sx={{ color: 'black', mb: 1, backgroundColor: 'white' }}
                    color='warning'
                    placeholder='Цена...'
                    type='number'
                    onChange={(event) => setProductPrice(event.target.value)}
                />
                <Input
                    sx={{ color: 'black', mb: 1, backgroundColor: 'white' }}
                    color='warning'
                    placeholder='Краткое описание...'
                    onChange={(event) => setProductSmallDesc(event.target.value)}
                />
                <Editor
                    editorState={editorState}
                    onEditorStateChange={handleEditorChange}
                    wrapperClassName="wrapper"
                    editorClassName="editor"
                    toolbarClassName="toolbar"
                    toolbar={{
                        options: ['inline'],
                        inline: {
                            options: ['bold', 'italic', 'underline', 'strikethrough'],
                        },
                    }}
                />
                <Card variant='plain' sx={{ mb: 1, border: '1px solid orange', backgroundColor: 'white' }}>
                    <DynamicInputs onChange={handleRewiewsChange} needSecond={true} name="Имя" secondName="Отзыв" title="Отзывы" />
                </Card>
                <Button onClick={() => handleSubmitUpload()} color='warning' sx={{ width: '100%', py: 2 }}>Добавить товар</Button>
            </div>
        );
    }

    return <div className="page">
        <form onSubmit={handleSubmit} style={{ width: '100%' }}>
            <Card>
                <Typography>Панель управления</Typography>
                <Input
                    sx={{ color: 'black' }}
                    color='warning'
                    placeholder='Имя пользователя...'
                    name="email"
                    type="email"
                />
                <Input
                    sx={{ color: 'black' }}
                    color='warning'
                    placeholder='Пароль...'
                    name="password"
                    type="password"
                />
                <Button color='warning' type="submit">Войти</Button>
            </Card>
        </form>
    </div>;
};

export default Panel;
