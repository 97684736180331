import './App.css';
import Header from './components/Header';
import Footer from './components/Footer';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ScrollToTop from './components/auto/ScrollToTop';

import Main from './pages/Main';
import About from './pages/About';
import Delivery from './pages/Delivery';
import Payment from './pages/Payment';
import Catalog from './pages/Catalog';
import ProductPage from './pages/ProductPage';
import Installments from './pages/Installments';
import Contacts from './pages/Contacts';
import Guarantee from './pages/Guarantee';
import PublicOffer from './pages/Public-offer';
import PrivacyPolicy from './pages/Privacy-policy';
import ErrorPage from './pages/ErrorPage';
import Panel from './pages/Panel';

function App() {
  return (
    <Router>
      <ScrollToTop />
      <Header />
      <Routes>
        <Route path='*' element={<ErrorPage />} />
        <Route path="/" element={<Main />} />
        <Route path="/about" element={<About />} />
        <Route path="/delivery" element={<Delivery />} />
        <Route path="/installments" element={<Installments />} />
        <Route path="/payment" element={<Payment />} />
        <Route path="/contacts" element={<Contacts />} />
        <Route path="/guarantee" element={<Guarantee />} />
        <Route path="/public-offer" element={<PublicOffer />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/catalog/:category?" element={<Catalog />} />
        <Route path="/product/:product?" element={<ProductPage />} />
        <Route path="/panel" element={<Panel />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
